import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import logo from './logo3dheaderWhite.svg';

const DeepLinkPreviewPage = () => {
  const location = useLocation();
  const path = location.pathname;
  const [storeUrl, setStoreUrl] = useState('');

  useEffect(() => {
    const handleRedirect = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
      
      // Intentar abrir la app primero
      const appUrl = isIOS ? `scala:/${path}` : `scala:/${path}`;
      const storeUrl = isIOS ? 'https://apple.co/4bugtF0' : 'https://play.google.com/store/apps/details?id=com.scala.app';
      setStoreUrl(storeUrl);
      // Intentar abrir la app
      window.location.href = appUrl;
      
      // Si después de 2 segundos no se ha abierto la app, redirigir a la tienda
      setTimeout(() => {
        window.location.href = storeUrl;
      }, 15000);
    };

    handleRedirect();
  }, [path]);

  return (
    <div className="min-h-screen bg-white flex items-center justify-center px-4">
      <div className="max-w-md w-full bg-white rounded-xl shadow-lg overflow-hidden border border-gray-200">
        <div className="bg-black p-8 text-white text-center">
          <img src={logo} alt="Scala" className="w-24 h-24 mx-auto mb-4" />
          <h1 className="text-2xl font-bold mb-2">Scala</h1>
          <p className="text-gray-300">Redirigiendo a la aplicación...</p>
        </div>
        
        <div className="p-6 text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-black mx-auto mb-4"></div>
          <p className="text-gray-600">
            Si no se abre automáticamente, 
            <br />
            <a 
              href={storeUrl} 
              className="text-black hover:text-gray-800 font-medium"
            >
              descarga la app aquí
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default DeepLinkPreviewPage; 