import { useRef, useEffect } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import bulletMockup from '../../../assets/images/bullet_home/bullet_mockup-compress.webp';

import happyEmoji from '../../../assets/images/bullet_home/happy-compress.webp';
import angryEmoji from '../../../assets/images/bullet_home/angry-compress.webp';
import sadEmoji from '../../../assets/images/bullet_home/sad-compress.webp';
import sleepyEmoji from '../../../assets/images/bullet_home/sleepy-compress.webp';
import confusedEmoji from '../../../assets/images/bullet_home/confused-compress.webp';
import cryingEmoji from '../../../assets/images/bullet_home/crying-compress.webp';

import MaskText from '../MaskTextEffect';

gsap.registerPlugin(ScrollTrigger);

export default function HeroBullet() {
  const sectionRef = useRef(null);

  useEffect(() => {
    const section = sectionRef.current;

    const isMobile = window.innerWidth <= 768; // Detecta si es móvil
    const emojiSize = isMobile ? 20 : 10; // Tamaño de los emojis en móvil y escritorio
    const animationDuration = isMobile ? 6 : 3; // Duración de la animación en móvil y escritorio
    const distanceX = isMobile ? 950 : 1800; // Duración de la animación en móvil y escritorio
    const distanceY = isMobile ? 1900 : 1000; // Duración de la animación en móvil y escritorio
    const positionY = isMobile ? -300 : 0; // Duración de la animación en móvil y escritorio

    // Animación de los emojis
    const emojiTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: section,
        start: "top 80%", // Inicia cuando el 80% de la sección entra en el viewport
      },
      onComplete: () => gsap.set('.content', { opacity: 1 }), // Muestra la sección principal después de los emojis
    });

    emojiTimeline.fromTo(
      '.emoji',
      { scale: 0, opacity: 0.8, x: 0, y: positionY },
      {
        scale: emojiSize,
        opacity: 1,
        delay: 0.3,
        x: (i) => (i % 2 === 0 ? -distanceX : distanceX),
        y: (i) => (i < 3 ? -distanceY : distanceY),
        duration: animationDuration,
        ease: 'power2.out',
        stagger: 0.15,
      }
    );

    // Animar el título, subtítulo, imagen y textos de forma escalonada
    gsap.from('.content > *', {
      opacity: 0,
      y: 50,
      stagger: 0.8,
      duration: 1,
      delay: 0.8,
      ease: 'power2.out',
      scrollTrigger: {
        trigger: section,
        start: "top 80%", // Inicia cuando el 80% de la sección entra en el viewport
      },
    });
  }, []);

  const emojis = [
    { src: happyEmoji, alt: 'Feliz' },
    { src: angryEmoji, alt: 'Enojado' },
    { src: sadEmoji, alt: 'Triste' },
    { src: sleepyEmoji, alt: 'Cansado' },
    { src: confusedEmoji, alt: 'Confundido' },
    { src: cryingEmoji, alt: 'Llorando' },
  ];

  return (
    <section ref={sectionRef} className="bg-white lg:pt-32 pt-28 lg:px-8 flex flex-col items-center relative overflow-hidden">
      {/* Emojis que se agrandan y desaparecen */}
      <div className="absolute inset-0 flex items-center justify-center z-10">
        {emojis.map((emoji, index) => (
          <img
            key={index}
            src={emoji.src}
            alt={emoji.alt}
            className="emoji absolute w-10 lg:w-16 h-auto"
          />
        ))}
      </div>

      {/* Contenido principal */}
      <div className="text-center z-20">
        {/* Título */}
        <MaskText
          text={['¿Cómo te sientes hoy?']}
          textSize="text-6xl/tight"
          responsiveTextSize="text-6xl/tight"
        />
        <div className="content">
          {/* Subtítulo */}
          <p className="text-lg text-gray-700 max-w-3xl px-7 lg:mt-3 my-7 mx-auto">
            Con el <strong>Bullet Journal</strong>, lleva un registro personal de tus emociones, reflexiona diariamente y conecta contigo mismo en solo unos minutos cada día.
          </p>

          {/* Contenedor de Imagen y Textos alrededor */}
          <div className="relative grid grid-cols-1 lg:grid-cols-5 items-center max-w-screen-2xl mx-auto lg:gap-0 gap-7 mt-10">
            {/* Texto izquierdo */}
            <div className="text-center row-start-1 lg:text-left lg:col-span-1 lg:row-span-1 lg:row-start-3 col-start-1">
              <p className="text-gray-750 text-xl font-medium max-w-xs lg:max-w-sm mx-auto lg:mt-[-50%] lg:me-[-50%]">
                <strong>Accede a tus registros pasados</strong> para entender tu evolución emocional y celebrar tu progreso.
              </p>
            </div>

            {/* Texto derecho superior */}
            <div className="text-center lg:text-left lg:col-span-1 lg:row-start-1 row-start-2 col-start-1 lg:col-start-5">
              <p className="text-gray-750 text-xl font-medium max-w-xs lg:max-w-sm mx-auto lg:mt-20 lg:ms-[-50%]">
                <strong>Responde preguntas diarias</strong> para profundizar en el autoconocimiento y cuidar de tu bienestar.
              </p>
            </div>

            {/* Texto derecho inferior */}
            <div className="text-center lg:text-left lg:col-span-1 lg:row-start-5 lg:col-start-5 row-start-3 col-start-1 ">
              <p className="text-gray-750 text-xl font-medium max-w-xs lg:max-w-sm mx-auto lg:mt-[-50%] lg:ms-[-50%]">
                <strong>Expresa gratitud</strong> y observa cómo cambia tu perspectiva día a día con tu bullet journal.
              </p>
            </div>

            {/* Imagen en el fondo */}
            <div className="relative w-full mt-10 lg:mt-0 col-span-1 lg:col-span-3 flex justify-center lg:col-start-2 lg:row-span-6">
              <img
                src={bulletMockup}
                alt="Mockup de teléfono"
                className="w-full max-sm:h-[650px] object-cover object-center"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
