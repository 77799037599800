import { useRef, useEffect } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import categorias_bento from '../../../assets/images/bento_home/categorias_bento-compress.webp';
import metas_bento from '../../../assets/images/bento_home/metas_bento-compress.webp';
import bullet_bento from '../../../assets/images/bento_home/bulletjournal_bento-compress.webp';
import home_bento from '../../../assets/images/bento_home/home_bento-compress.webp';
import image_bento from '../../../assets/images/bento_home/image_bento-compress.webp';
import MaskText from '../MaskTextEffect';

gsap.registerPlugin(ScrollTrigger);

export default function HeroBento() {
  const elementsRef = useRef([]);

  useEffect(() => {
    gsap.from(elementsRef.current, {
      opacity: 0,
      y: 50,
      stagger: 0.1,
      duration: 0.3,
      ease: 'power3.out',
      scrollTrigger: {
        trigger: '#bentoContainer',
        start: 'top center',
        end: 'bottom 80%',
        toggleActions: 'play none none none',
        scrub: true,
      },
    });
  }, []);

  return (
    <div id="bentoContainer" className="hero-container py-16 sm:py-18">
      <div className="mx-auto max-w-2xl px-6 lg:max-w-7xl lg:px-8">
        <MaskText text={['La motivación', 'no se detiene.']} />
        <div className="mt-10 grid gap-8 sm:mt-16 grid-cols-1 lg:grid-cols-2 lg:auto-rows-[1fr]">
          {/* Sección 1 */}
          <div
            ref={(el) => (elementsRef.current[0] = el)}
            className="relative flex flex-col"
          >
            <div className="absolute inset-0 rounded-[15px] bg-[#F4F5F7]"></div>
            <div className="relative flex flex-col h-full rounded-[15px] overflow-hidden">
              <div className="px-8 pt-8 sm:px-10">
                <p className="text-3xl max-lg:text-2xl font-semibold text-gray-950 text-center">
                  10 categorías
                </p>
                <p className="my-1 text-lg text-gray-500 text-center">
                  para clasificar tus metas
                </p>
              </div>
              <div className="flex-grow flex items-end justify-center max-lg:mt-10">
                <img
                  className="w-full max-w-[300px] lg:max-w-sm pb-16 object-contain"
                  src={categorias_bento}
                  alt="10 categorías"
                />
              </div>
            </div>
          </div>

          {/* Sección 2 */}
          <div
            ref={(el) => (elementsRef.current[1] = el)}
            className="relative flex flex-col"
          >
            <div className="absolute inset-0 rounded-[15px] bg-[#F4F5F7]"></div>
            <div className="relative flex flex-col h-full rounded-[15px] overflow-hidden">
              <div className="px-8 pt-8 sm:px-10">
                <p className="text-3xl max-lg:text-2xl font-semibold text-gray-950 text-center">
                  3 tipos de metas
                </p>
                <p className="my-1 text-lg text-gray-500 text-center">
                  Define objetivos, crea hábitos y acepta retos
                </p>
              </div>
              <div className="flex-grow flex items-end justify-center max-lg:mt-10">
                <img
                  className="w-full max-w-[300px] lg:max-w-[450px] object-contain"
                  src={metas_bento}
                  alt="3 tipos de metas"
                />
              </div>
            </div>
          </div>

          {/* Sección 3 */}
          <div
            ref={(el) => (elementsRef.current[2] = el)}
            className="relative flex flex-col"
          >
            <div className="absolute inset-0 rounded-[15px] bg-[#F4F5F7]"></div>
            <div className="relative flex flex-col h-full rounded-[15px] overflow-hidden">
              <div className="px-8 pt-8 sm:px-10">
                <p className="text-3xl max-lg:text-2xl font-semibold text-gray-950 text-center">
                  1 imagen
                </p>
                <p className="my-1 text-lg text-gray-500 text-center">
                  para verificar tu logro
                </p>
              </div>
              <div className="flex-grow flex items-end max-lg:my-14 justify-center">
                <img
                  className="w-full max-w-[300px] lg:max-w-sm lg:pb-8 object-contain"
                  src={image_bento}
                  alt="1 imagen"
                />
              </div>
            </div>
          </div>

          {/* Sección 4 */}
          <div
            ref={(el) => (elementsRef.current[3] = el)}
            className="relative flex flex-col"
          >
            <div className="absolute inset-0 rounded-[15px] bg-[#F4F5F7]"></div>
            <div className="relative flex flex-col h-full rounded-[15px] overflow-hidden">
              <div className="px-8 pt-8 sm:px-10">
                <p className="text-3xl max-lg:text-2xl font-semibold text-gray-950 text-center">
                  Comparte tu progreso
                </p>
                <p className="my-1 text-lg text-gray-500 text-center">
                  y motiva a tus amigos
                </p>
              </div>
              <div className="flex-grow flex items-end justify-center max-lg:mt-14">
                <img
                  className="w-full max-w-[300px] object-contain"
                  src={home_bento}
                  alt="Comparte tu progreso"
                />
              </div>
            </div>
          </div>

          {/* Sección 5 */}
          <div
            ref={(el) => (elementsRef.current[4] = el)}
            className="relative lg:col-span-2 flex flex-col lg:flex-row"
          >
            <div className="absolute inset-0 rounded-[15px] bg-[#F4F5F7]"></div>
            <div className="relative flex flex-col lg:flex-row lg:justify-between h-full rounded-[15px] overflow-hidden">
              <div className="flex flex-col justify-center px-8 pt-8 sm:px-10 lg:mb-14 lg:w-1/2 max-lg:mb-14">
                <h2 className="bg-[#00FF66] text-gray-950 text-sm font-semibold rounded py-1 px-2 w-fit">
                  Novedad
                </h2>
                <p className="text-3xl max-lg:text-2xl font-semibold text-gray-950 mt-2">
                  Bullet Journal
                </p>
                <p className="mt-2 text-lg text-gray-500">
                  Registra cómo te sientes, responde preguntas que te hacen pensar y observa tu propio progreso.
                </p>
                <p className="mt-2 text-lg text-gray-500">
                  Scala te ayuda a conocerte mejor y a crecer cada día.
                </p>
                <a
                  href="/download"
                  className="cursor-pointer hover:bg-black mt-6 hover:text-white text-lg w-fit text-center hover:border-black font-medium py-2 px-4 rounded-[7px] no-underline hover:no-underline bg-white text-black transition-colors duration-300"
                >
                  Descarga la app
                </a>
              </div>
              <div className="flex-grow flex items-end justify-center px-8 lg:w-1/2">
                <img
                  className="w-full max-w-[400px] lg:pt-10 object-contain"
                  src={bullet_bento}
                  alt="Bullet Journal"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
